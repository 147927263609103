/* CodeSnippet.css */

/* Transition effect for fading in characters */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

/* Add a color transition effect */

.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
}
/* Hide horizontal scrollbar for Chrome, Safari, and Opera */
.hide-horizontal-scrollbar::-webkit-scrollbar:horizontal {
    display: none;
}

/* Hide horizontal scrollbar for IE, Edge, and Firefox */
.hide-horizontal-scrollbar {
    overflow-x: hidden; /* Disable horizontal scrolling */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Add smooth scrolling effect */
.hide-horizontal-scrollbar {
    scroll-behavior: smooth;
}
.scrollxz {
    overflow-x: hidden;
    overflow-y: hidden;
}

pre[class*="language-"]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #2d2d2d !important;
    /* border-radius: 10px; */
}

pre[class*="language-"]::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    background-color: #2d2d2d !important;
}

pre[class*="language-"]::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    background: #2d2d2d !important;
}
