/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
    /* Vertical Line*/
    .child:nth-child(2) {
        position: relative;
    }

    /* Vertical Line 1 || */
    .child:nth-child(2)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 10px;
        /* Adjust line width here */
        height: 650%;
        /* Adjust line height here */
        top: 40%;
        left: -150%;
    }

    /*horizontall 2*/
    .child:nth-child(2)::after {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 350%;
        /* Adjust line height here */
        bottom: 50%;
        top: 70%;
        left: -150%;
    }

    /* Horizontal Line*/
    .child:nth-child(1) {
        position: relative;
        border-bottom: 2px solid transparent;
        /* Adjust line width here */
    }

    /* Horizontal Line 1==*/
    .child:nth-child(1)::before {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 350%;
        /* Adjust line height here */
        bottom: 50%;
        top: -50%;
        left: 170%;
    }

    /* horizontally line 3 */
    .child:nth-child(1)::after {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 350%;
        /* Adjust line height here */
        bottom: 50%;
        top: 220%;
        left: 200%;
    }

    .child:nth-child(3) {
        position: relative;
    }

    /* horizontally line 6 */
    .child:nth-child(3)::before {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 350%;
        /* Adjust line height here */
        bottom: 50%;
        top: 195%;
        left: 170%;
    }

    /* horizontally line 5 */
    .child:nth-child(3)::after {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 350%;
        /* Adjust line height here */
        bottom: 50%;
        top: 310%;
        left: 200%;
    }
}

@media (min-width: 1500px) {
    /* Vertical Lines */
    .child:nth-child(2) {
        position: relative;
    }

    .child:nth-child(3) {
        position: relative;
    }

    /* Vertical Line 1 */
    .child:nth-child(3)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        left: -500%;
    }

    /* Vertical Line 2 */
    .child:nth-child(2)::after {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        right: -200%;
    }

    /* Additional Vertical Line */
    .child:nth-child(2)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        right: -750%;
    }

    /* Horizontal Line*/
    .child:nth-child(1) {
        position: relative;
        border-bottom: 3px solid transparent;
        /* Adjust line width here */
    }

    .child:nth-child(1)::before {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 200px;
        /* Adjust line width here */
        height: 900%;
        /* Adjust line height here */
        bottom: 0;
        top: -90%;
        left: 500%;
    }

    /* Horizontal Line 1*/
    .child:nth-child(1)::after {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 200px;
        /* Adjust line width here */
        height: 900%;
        /* Adjust line height here */
        bottom: 0;
        top: -230%;
        left: 500%;
    }
}

/* laptop */
@media (min-width: 1024px) and (max-width: 1439px) {
    /* Vertical Lines */
    .child:nth-child(2) {
        position: relative;
    }

    .child:nth-child(3) {
        position: relative;
    }

    /* Vertical Line 1 */
    .child:nth-child(3)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        left: -300%;
    }

    /* Vertical Line 2 */
    .child:nth-child(2)::after {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        right: -140%;
    }

    /* Vertical Line 3 */
    .child:nth-child(2)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        right: -460%;
    }

    /* Horizontal Line*/
    .child:nth-child(1) {
        position: relative;
        border-bottom: 3px solid transparent;
        /* Adjust line width here */
    }

    /* Horizontal Line 2*/
    .child:nth-child(1)::before {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 200px;
        /* Adjust line width here */
        height: 600%;
        /* Adjust line height here */
        bottom: 0;
        top: 40%;
        left: 300%;
    }

    /* Horizontal Line 1*/
    .child:nth-child(1)::after {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 200px;
        /* Adjust line width here */
        height: 600%;
        /* Adjust line height here */
        bottom: 0;
        top: -90%;
        left: 300%;
    }
}

/* laptop l*/
@media (min-width: 1439px) and (max-width: 1499px) {
    /* Vertical Lines */
    .child:nth-child(2) {
        position: relative;
    }

    .child:nth-child(3) {
        position: relative;
    }

    /* Vertical Line 1 */
    .child:nth-child(3)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        left: -400%;
    }

    /* Vertical Line 2 */
    .child:nth-child(2)::after {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        right: -180%;
    }

    /* Vertical Line 3 */
    .child:nth-child(2)::before {
        position: absolute;
        display: block;
        content: "";
        border-right: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
        width: 2px;
        /* Adjust line width here */
        height: 400%;
        /* Adjust line height here */
        top: 0%;
        right: -620%;
    }

    /* Horizontal Line*/
    .child:nth-child(1) {
        position: relative;
        border-bottom: 3px solid transparent;
        /* Adjust line width here */
    }

    /* Horizontal Line 2*/
    .child:nth-child(1)::before {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 200px;
        /* Adjust line width here */
        height: 900%;
        /* Adjust line height here */
        bottom: 0;
        top: -90%;
        left: 400%;
    }

    /* Horizontal Line 1*/
    .child:nth-child(1)::after {
        position: absolute;
        display: block;
        content: "";
        rotate: 90deg;
        border-left: 2px solid transparent;
        /* Adjust line width here */
        border-image: linear-gradient(to top, #ffffff, #410ead, #ffffff) 1;
        width: 200px;
        /* Adjust line width here */
        height: 900%;
        /* Adjust line height here */
        bottom: 0;
        top: -240%;
        left: 400%;
    }
}
