.flag-dropdown {
  background-color: white !important;
  border: none !important;
}
.flag-dropdown:hover {
  background-color: transparent !important;
}
.react-tel-input .selected-flag {
  padding: 0% !important;
  margin: 0% !important;
}
