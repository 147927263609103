.Typewriter__wrapper {
    font-family: var(--font-manrope);
    font-style: normal;
}

.formula .Typewriter__wrapper,
.formula {
    font-family: var(--font-manrope);
    /* font-family: "Allerta Stencil", sans-serif; */
}

.Typewriter {
    margin: 8px 0px;
}
